<template>
  <div class="container">
    <div @click="goback" style="font-size:14px;margin-bottom:10px;cursor: pointer;width:50px">
      <i class="el-icon-back"></i>
      返回
    </div>
    <el-form :model="form" :rules="rules" ref="form" style="width:780px">
      <el-form-item label="语言" prop="language_code" label-width="130px">
        <el-select v-model="form.language_code">
          <el-option v-for="item in lanlist" :key="item.code" :label="item.name" :value="item.code"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="内容" label-width="130px">
        <el-input type="textarea" :rows="10" v-model="form.content" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label-width="130px">  
        <el-button type="primary" @click="Validate(AddSubmit)">{{edit}}</el-button>
      </el-form-item>
    </el-form>

  </div>
</template>

<script type="text/ecmascript-6">
import Editor from "../../../components/richtext/index.vue";
import util from '../../../util/util'
export default {
  name: '',
  props: {},
  setup () {
    return {
    }
  },
  mixins: [],
  components: {
    Editor
  },
  data () {
    return {
      templateCode: '',
      textCode: '',
      edit: '',
      form: {
        code: '',
        message_template_head_code: '',
        language_code: '',
        content: '',
      },
      rules: {
        language_code: [{ required: true, message: '请选择语言', trigger: 'blur' }],
        content: [{ required: true, message: '请输入内容', trigger: 'blur' }],
      },
      lanlist: [],
      detail: '',
      richtext: '',
    }
  },
  computed: {},
  watch: {},
  methods: {
    editorchange (html) {
      // 1.获取内容url
      this.richtext = html
    },
    GetLanguage () {
      this.api.LanguageList({
        page: 1,
        limit: 100
      }).then(res => {
        if (res.data.code == 200) {
          this.lanlist = res.data.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    GetInfo () {
      this.api.TemplateTextDetail({
        code: this.textCode
      }).then(res => {
        if (res.data.code == 200) {
          this.form = res.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    Validate: function (call) {
      this.$refs['form'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    AddSubmit () {
      this.api.TemplateTextUpdate({
        code: this.form.code,
        message_template_head_code: this.templateCode,
        language_code: this.form.language_code,
        content: this.form.content,
      }).then(res => {
        if (res.data.code == 200) {
          this.form = {}
          this.$confirm(this.edit+'成功,是否返回列表页?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.goback()
          })
          return
        }
        this.$message.error(this.edit + '失败' + res.data.msg)
      })
    },
    goback () {
      this.$router.push('/message/templatemessageadd/' + this.templateCode)
    }
  },
  filters: {},
  mounted () {
    this.templateCode = this.$route.params.code.split('&')[0]
    this.textCode = this.$route.params.code.split('&')[1]
    if (this.textCode == 0) {
      this.edit = '创建'
    } else {
      this.edit = '修改'
      this.GetInfo()
    }
  },
  created () {
    this.GetLanguage()
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
